import React, { useEffect, useState } from "react";
import Pagination from "../shared/pagination";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";

const UserPlinkoBets = (props) => {
  const [data, setData] = useState({ bets: [], total_pages: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetch(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const fetch = async (currentPage) => {
    setIsLoading(true);
    try {
      const { total_pages, records } = await window.api.get(
        `/admin/users/${props.match.params.id}/plinko_bets?page=${currentPage}`
      );
      setData({ total_pages, bets: records });
    } catch (err) {
      toast.error(err.message);
    }
    setIsLoading(false);
  };

  return (
    <div style={{ position: "relative", "min-height": "500px" }}>
      {isLoading && (
        <div className={"loading-overlay"}>
          <h2>Loading, please wait ...</h2>
          <div className="loader" />
        </div>
      )}
      <Table>
        <Thead>
          <Tr>
            <Th>Round ID</Th>
            <Th>Bet Placed At</Th>
            <Th>Bet Settled At</Th>
            <Th>Amount</Th>
            <Th>Multiplier</Th>
            <Th>Risk</Th>
            <Th>Rows</Th>
            <Th>Roll</Th>
            <Th>Profit</Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.bets.map((r, i) => {
            const payout = r.win_amount > 0 ? r.profit : -1 * r.amount;

            return (
              <Tr key={r.round_id}>
                <Td>
                  <Link to={`/casino/game-provider-rounds/${r.round_id}`}>
                    {r.round_id}
                  </Link>
                </Td>

                <Td>{format.withSeconds(r.bet_placed_at)}</Td>
                <Td>{format.withSeconds(r.bet_settled_at)}</Td>
                <Td>
                  {r.amount} {r.currency_type}
                </Td>
                <Td>{(+r.multiplier).toFixed(2)}</Td>
                <Td>{r.risk}</Td>
                <Td>{r.rows}</Td>
                <Td>{r.roll}</Td>
                <Td className={payout >= 0 ? "good" : "bad"}>
                  {(+payout).toFixed(2)} {r.currency_type}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Pagination
        total_pages={data.total_pages}
        update={(page) => setPage(page)}
      />
    </div>
  );
};

export default UserPlinkoBets;
