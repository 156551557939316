

import React, {Component} from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import Select from "react-select";
import { Link } from "react-router-dom";
import { updateRewards } from "../casino/reward-actions";
import {toast} from "react-toastify";
import {checkPermissions, permissionsConstants, PermissionsFragment} from "../app/permissions-fragment";
import { format } from '../shared/utils/dates';
import { REWARD_CONSUMED_STATUS, REWARD_CREATED_STATUS, REWARD_REMOVED_STATUS, REWARD_SOLD_STATUS } from './constants';

class UserRewardInventory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      page: 1,
      results: {
        pagination: {
          total_pages: 1,
          records_per_page: 50
        },
        records: []
      },
      edit_row: {},
      creating: false,
      cashbackDetailsLoading: false
    }
  }

  componentDidMount() {
    this.update();
    if (checkPermissions(permissionsConstants.REWARDS_VIEW_LIST)) {
      this.props.dispatch(updateRewards());
    }
  }

  getBase() {
    return `/admin/users/${this.props.match.params.id}/reward_manifests`
  }

  update() {let params = {};
    if (this.table) {
      params = {
        page: this.table.state.page + 1,
        sorting: this.table.state.sorted[0],
        filters: this.table.state.filtered,
        show_inactive: this.state.show_inactive ? '1' : '0'
      }
    }

    window.api.get(this.getBase(), {
      params: params
    }).then(data => {
      this.setState({
        results: data
      });
    }).catch(err => {
      toast.error(err);
    });
  }

  save() {

    const { edit_row, creating } = this.state;
    if (creating) {
      window.api.post(this.getBase(), edit_row)
        .then(data => {
          this.setState({
            edit_row: {},
            creating: false
          });
          this.update();
          if (data.status === REWARD_CONSUMED_STATUS) {

            toast.success(`Reward added (+instant consumed)`);
          } else {
            toast.success(`Reward added successfully`);
          }
        }).catch(err => {
        toast.error(err);
      });
    } else {
      window.api.put(`${this.getBase()}/${edit_row.id}`, edit_row)
        .then(data => {
          this.setState({
            edit_row: {}
          });
          this.update();
        }).catch(err => {
        toast.error(err);
      });
    }
  }

  cancel() {
    if (this.state.creating) {
      this.setState({
        creating: false
      })
    }

    this.setState({
      edit_row: {}
    })
  }

  async getCashbackDetails(rewardManifestId) {
    this.setState({ cashbackDetailsLoading: true })
    const data = await window.api.get(`/admin/users/${this.props.match.params.id}/cashback_reward/${rewardManifestId}/details`);
    alert(`
      Period started: ${data.period_data.created_at}
      Period expired: ${data.period_data.expires_at}
      Period rakeback percentage: ${data.period_data.rakeback_percentage}
      
      Real bet in period (normalized into EUR): ${data.real_eur_bet_in_period} EUR
      Real won in period (normalized into EUR): ${data.real_eur_won_in_period} EUR
      
      Amount credited: ${data.period_data.amount_credited} ${data.period_data.amount_credited_currency}
    `);
    this.setState({ cashbackDetailsLoading: false })
  }

  render() {
    const { edit_row, creating } = this.state;
    let { pagination, records } = this.state.results;

    if (creating) {
      records = [edit_row].concat(records);
    }

    return (
      <div>

        <PermissionsFragment feature={permissionsConstants.USER_REWARDS_INVENTORY_ADD_REWARD}>
          <button
            onClick={
              e => this.setState({
                creating: true,
                edit_row: {
                  id: null,
                  name: 'New'
                }
              })
            }
          >New

          </button>
        </PermissionsFragment>

        <div style={{display: 'flex', alignItems: 'center'}}>
          <h4>Show consumed/sold?</h4>
          <input
            style={{width: '20px'}}
            type="checkbox"
            checked={this.state.show_inactive}
            onChange={() => {
              this.setState({
                show_inactive: !this.state.show_inactive
              }, this.update);
            }}
          />
        </div>

        <ReactTable
          ref={el => this.table = el}
          manual
          showPageJump={false}
          pages={pagination['total_pages']}
          pageSize={pagination['records_per_page']}
          data={records}
          multiSort={false}
          showPageSizeOptions={false}
          onPageChange={(pageIndex) => {
            this.update();
          }}
          onSortedChange={(newSorted, column, shiftKey) => {
            this.update();
          }}
          onFilteredChange={(filtered, column, value) => {
            this.update();
          }}
          columns={[
            {
              Header: '',
              Cell: d => {
                return (
                  <PermissionsFragment feature={permissionsConstants.USER_REWARDS_INVENTORY_UPDATE_REWARD}>
                    { edit_row.id === d.original.id ?
                      <div>
                        <a
                          onClick={
                            e => this.cancel()
                          }
                        >
                          Cancel
                        </a>
                        <a
                          onClick={
                            e => this.save()
                          }
                        >
                          Save
                        </a>
                      </div>
                      :
                      <a
                        onClick={
                          e => this.setState({
                            edit_row: d.original
                          })
                        }
                      >
                        Edit
                      </a>
                    }
                  </PermissionsFragment>
                )
              },
              maxWidth: 100
            },
            {
              id: 'id',
              Header: 'ID',
              accessor: d => (
                <Link to={`/items/${d.id}`}>{d['id']}</Link>
              ),
              minWidth: 70,
            },
            {
              id: 'created_at',
              Header: 'Created',
              accessor: d => format.withMinutes(d['created_at']),
              maxWidth: 200,
              minWidth: 130,
            },
            {
              id: 'reward_id',
              Header: 'Reward',
              accessor: d => (
                edit_row.id === d.id ?
                  <Select
                    name="type"
                    value={edit_row.reward_id}
                    onChange={option => {
                      option = option || { value: null };
                      this.setState({
                        edit_row: {
                          ...edit_row,
                          reward_id: option.value
                        }
                      })
                    }}
                    options={
                      this.props.rewards.map(
                        reward => ({
                          label: <div style={{display: 'flex', alignItems: 'center'}}>
                            <img style={{width: '30px'}} src={reward.image_url}/>
                            (ID: ${reward.id}) - ${reward.name} (€ ${reward.eur_value})
                          </div>,
                          value: reward.id
                        })
                      )
                    }
                  />
                  :
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <img style={{width: '30px'}} src={d.reward.image_url}/>
                    <Link to={`/casino/${d.reward.id}`}>
                      {d.reward.name}</Link>
                  </div>
              ),
              maxWidth: 400,
              minWidth: 260,
            },

            {
              id: 'status',
              Header: 'Status',
              accessor: d => (
                edit_row.id === d.id ?
                  <Select
                    name="type"
                    value={edit_row.status}
                    onChange={option => {
                      option = option || { value: null };
                      this.setState({
                        edit_row: {
                          ...edit_row,
                          status: option.value || null
                        }
                      })
                    }}
                    options={[
                      { label: 'Created', value: REWARD_CREATED_STATUS },
                      { label: 'Consumed', value: REWARD_CONSUMED_STATUS },
                      { label: 'Sold', value: REWARD_SOLD_STATUS },
                      { label: 'Removed', value: REWARD_REMOVED_STATUS }
                    ]}
                  />
                  :
                  <span>{d.status}</span>
              ),
              maxWidth: 200
            },
            {
              id: 'consumed',
              Header: 'Consumed',
              accessor: d => d['status'] === REWARD_CONSUMED_STATUS && format.withMinutes(d['updated_at']),
              minWidth: 130,
            },
            {
              id: 'expiration',
              Header: 'Expiration',
              accessor: d => d['expires_at'] &&  format.withMinutes(d['expires_at']),
              minWidth: 130,
            },
            {
              id: 'details',
              Header: 'Details',
              accessor: d => d.reward && d.reward.type === 'cashback' &&
                <button disabled={this.state.cashbackDetailsLoading} onClick={() => this.getCashbackDetails(d.id)} className="small">View details</button>
            },
            {
              id: 'note',
              Header: 'Note',
              accessor: 'note',
            }
          ]}
        />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  rewards: state.rewards.items.records,
  user_ranks: state.app.user_ranks,
  user: state.users.user
});


export default connect(mapStateToProps)(UserRewardInventory);