import React from "react";
import { NavLink } from "react-router-dom";
import permissionGroups from "../../app/permission-groups";
import { GroupPermissionsFragment } from "app/permissions-fragment";

export const BigWinsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.BIG_WINS}>
    <NavLink to="/casino/big-wins">Big wins</NavLink>
  </GroupPermissionsFragment>
);

export const CurrenciesLink = () => (
  <GroupPermissionsFragment group={permissionGroups.SUPPORTED_CURRENCIES}>
    <NavLink to="/casino/currencies">Supported currencies</NavLink>
  </GroupPermissionsFragment>
);
export const GameProvidersLink = () => (
  <GroupPermissionsFragment group={permissionGroups.GAMES.GAME_PROVIDERS}>
    <NavLink exact to={`/casino/game-providers`}>
      Game Providers
    </NavLink>
  </GroupPermissionsFragment>
);

export const GameCategoriesLink = () => (
  <GroupPermissionsFragment group={permissionGroups.GAMES.GAME_CATEGORIES}>
    <NavLink exact to={`/casino/game-categories`}>
      Game Categories
    </NavLink>
  </GroupPermissionsFragment>
);

export const GamesLink = () => (
  <GroupPermissionsFragment group={permissionGroups.BETS.ONLY_GAMES}>
    <NavLink exact to={`/casino/games`}>
      Games
    </NavLink>
  </GroupPermissionsFragment>
);

export const RewardsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.REWARDS.GENERAL}>
    <NavLink exact to="/casino/rewards">
      Rewards
    </NavLink>
  </GroupPermissionsFragment>
);

export const MysteryBoxesLink = () => (
  <GroupPermissionsFragment group={permissionGroups.REWARDS.MYSTERY_BOXES}>
    <NavLink to="/casino/mystery-boxes">Mystery Boxes</NavLink>
  </GroupPermissionsFragment>
);

export const RewardTiersLink = () => (
  <GroupPermissionsFragment group={permissionGroups.REWARDS.REWARD_TIERS}>
    <NavLink to="/casino/reward-tiers">Reward Tiers</NavLink>
  </GroupPermissionsFragment>
);

export const UserRanksLink = () => (
  <GroupPermissionsFragment group={permissionGroups.REWARDS.USER_RANKS}>
    <NavLink to="/casino/user-ranks">User Ranks</NavLink>
  </GroupPermissionsFragment>
);

export const GlobalXpLink = () => (
  <GroupPermissionsFragment group={permissionGroups.REWARDS.GLOBAL_XP}>
    <NavLink to="/casino/global-xp">Global Xp</NavLink>
  </GroupPermissionsFragment>
);

export const PromoCodesLink = () => (
  <GroupPermissionsFragment group={permissionGroups.REWARDS.PROMO_CODES}>
    <NavLink to="/casino/promo-codes">Promo Codes</NavLink>
  </GroupPermissionsFragment>
);

export const BonusesLink = () => (
  <GroupPermissionsFragment group={permissionGroups.REWARDS.BONUSES}>
    <NavLink to="/casino/bonuses">Bonuses</NavLink>
  </GroupPermissionsFragment>
);

export const RacesLink = () => (
  <GroupPermissionsFragment group={permissionGroups.REWARDS.RACES}>
    <NavLink to="/casino/races">Races</NavLink>
  </GroupPermissionsFragment>
);

export const ChallengesLink = () => (
  <GroupPermissionsFragment group={permissionGroups.REWARDS.CHALLENGES}>
    <NavLink to="/casino/challenges">Challenges</NavLink>
  </GroupPermissionsFragment>
);

export const LoyaltyLink = () => (
  <GroupPermissionsFragment group={permissionGroups.REWARDS.LOYALTY}>
    <NavLink to="/casino/loyalty">Loyalty</NavLink>
  </GroupPermissionsFragment>
);

export const AffiliateLinksLink = () => (
  <GroupPermissionsFragment group={permissionGroups.MARKETING.AFFILIATE_LINKS}>
    <NavLink to="/casino/affiliate-links">Affiliate Links</NavLink>
  </GroupPermissionsFragment>
);

const links = {
  GameProvidersLink,
  GameCategoriesLink,
  GamesLink,
  RewardsLink,
  MysteryBoxesLink,
  RewardTiersLink,
  UserRanksLink,
  GlobalXpLink,
  PromoCodesLink,
  BonusesLink,
  RacesLink,
  LoyaltyLink,
  AffiliateLinksLink,
  BigWinsLink,
  ChallengesLink,
  CurrenciesLink,
};

export default links;
