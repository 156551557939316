import React, { useState } from "react";
import { useQuery } from "react-query";
import { OffsetPagination } from "../shared/OffsetPagination";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import MobilePageTitle from "shared/MobilePageTitle";
import styles from "./tab-admin-analytics.module.scss";
import Select from "react-select";

const AdminAnalytics = () => {
  const [target_id, setTargetId] = useState("");
  const [admin_id, setAdminId] = useState("");
  const [action, setAction] = useState("");
  const [ip, setIp] = useState("");
  const [page, setPage] = useState(1);
  const perPage = 50;

  const fetchAnalytics = async () => {
    return window.api({
      method: "GET",
      url: `/admin/custom-analytics/?${queryParams}`,
    });
  };

  const fetchActionTypes = async () => {
    return window.api({
      method: "GET",
      url: `/admin/custom-analytics/action-types`,
    });
  };

  const queryParams = new URLSearchParams({
    target_id,
    admin_id,
    action_type: action,
    ip_address: ip,
    page: page.toString(),
    perPage: perPage.toString(),
  }).toString();

  const {
    data: { records = [], total } = {},
    isLoading,
    isError,
    error,
    isSuccess,
  } = useQuery(["custom-analytics", queryParams], fetchAnalytics);

  const {
    data: dataActionTypes,
    actionTypesisLoading,
  } = useQuery(["custom-analytics-action-types"], fetchActionTypes);


  const totalPages = Math.ceil(perPage ? (total || 0) / perPage : 0);

  if (isLoading) {
    return (
      <div className="bet-page">
        <h2>Loading...</h2>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="bet-page">
        <h2>{error.message}</h2>
      </div>
    );
  }

  return (
    <div className="bet-page">
      <MobilePageTitle title="Admin analytics" />
      <div className={styles.container}>
        <div className={styles.box}>
          <input
            style={{ margin: 10 }}
            value={admin_id}
            onChange={({ target }) => setAdminId(target.value || "")}
            placeholder="Type a Admin ID"
          />

          <button
            disabled={isLoading}
            className={`red ${styles.button}`}
            onClick={fetchAnalytics}
          >
            {isLoading ? "Loading..." : "Search"}
          </button>
        </div>
        <div className={styles.box}>
          <input
            style={{ margin: 10 }}
            value={target_id}
            onChange={({ target }) => setTargetId(target.value || "")}
            placeholder="Type a Target ID"
          />

          <button
            disabled={isLoading}
            className={`red ${styles.button}`}
            onClick={fetchAnalytics}
          >
            {isLoading ? "Loading..." : "Search"}
          </button>
        </div>

        <div className={styles.box}>
          <input
            style={{ margin: 10 }}
            value={ip}
            onChange={({ target }) => setIp(target.value || "")}
            placeholder="Type a IP address"
          />

          <button
            disabled={isLoading}
            className={`red ${styles.button}`}
            onClick={fetchAnalytics}
          >
            {isLoading ? "Loading..." : "Search"}
          </button>
        </div>

        <div className={styles.box}>

          <Select
              placeholder={"Select Action Type"}
              style={{ width: 200, marginRight: 10, background: "#3b3f50" }}
              name="type"
              value={action}
              onChange={(option) => setAction(option?.value || "")}
              isLoading={actionTypesisLoading}
              options={actionTypesisLoading ? [] : dataActionTypes?.records?.map((value) => ({
                label: value,
                value: value,
              }))}
          />

        </div>


      </div>
      <div className={styles.pagination}>
        <OffsetPagination
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>

      {isSuccess && (
        <div className="row">
          <Table>
            <Thead>
              <Tr>
                <Th>Admin ID</Th>
                <Th>Target ID</Th>
                <Th>Action</Th>
                <Th>IP Address</Th>
                <Th>Created At</Th>
                <Th>Notes</Th>
              </Tr>
            </Thead>
            <Tbody>
              {records.map((item) => {
                return (
                  <Tr key={item.id}>
                    <Td>{item.admin_user_id}</Td>
                    <Td>{item.target_id}</Td>
                    <Td>{item.action_type}</Td>
                    <Td>{item.ip_address}</Td>
                    <Td>{item.created_at}</Td>
                    <Td>{item.notes}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
      )}
    </div>
  );
  // }
};

export default AdminAnalytics;
