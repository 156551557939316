import React, { Component } from "react";
import Pagination from "../shared/pagination";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserBets } from "../originals/originals-games-api";
import { format } from "../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
export default class UserMinesBets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      total_pages: 0,
      bets: [],
      queryFilter: {},
      hasChange: false,
      gameStartedAtStart: null,
      gameStartedAtEnd: null,
      gameSettledAtStart: null,
      gameSettledAtEnd: null,
      joinDateStarted: null,
      lastDepositStarted: null,
      joinDateSettled: null,
      lastDepositSettled: null,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.page !== this.state.page) {
      this.fetch();
    }
  }

  async fetch() {
    this.setState({ isLoading: true });
    try {
      const data = await getUserBets(this.props.match.params.id, {
        page: this.state.page,
        type: "mines",
      });
      this.setState({ total_pages: data.pages, bets: data.records });
    } catch (err) {
      toast.error(err.message);
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div style={{ position: "relative", "min-height": "500px" }}>
        {this.state.isLoading && (
          <div className={"loading-overlay"}>
            <h2>Loading, please wait ...</h2>
            <div className="loader" />
          </div>
        )}
        <Table>
          <Thead>
            <Tr>
              <Th>Bet ID</Th>
              <Th>Game Started At</Th>
              <Th>Amount</Th>
              <Th>Number of mines</Th>
              <Th>Tiles shown</Th>
              <Th>Free</Th>
              <Th>Winnings</Th>
              <Th>Status</Th>
              <Th>Profit</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.state.bets.map((r, i) => {
              return (
                <Tr key={i}>
                  <Td>
                    {r.wallet_params?.free_bet ? (
                      r.id
                    ) : (
                      <Link
                        to={`/casino/game-provider-rounds/${r.remoteRoundId}`}
                      >
                        {r.id}
                      </Link>
                    )}
                  </Td>
                  <Td>{format.withSeconds(r.created_at)}</Td>
                  <Td>
                    {r.amount} {r.currency_type}
                  </Td>
                  <Td>{r.number_of_mines}</Td>
                  <Td>{r.tiles_shown}</Td>
                  <Td>{r.wallet_params?.free_bet ? "Yes" : "No"}</Td>
                  <Td className="good">
                    {r.winnings} {r.currency_type}
                  </Td>
                  <Td>{r.status}</Td>
                  <Td className={r.profit >= 0 ? "good" : "bad"}>
                    {r.profit} {r.currency_type}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Pagination
          total_pages={this.state.total_pages}
          update={(page) => {
            this.setState({ page });
          }}
        />
      </div>
    );
  }
}
