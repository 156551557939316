

export const KYC_TYPES = ['bank_iban', 'credit_card', 'credit_card_switch', 'id', 'address', 'proof_of_deposit', 'source_of_funds'];

export const KYC_NOTICE_TYPES = ['bank_iban', 'credit_card', 'id', 'address', 'proof_of_deposit', 'source_of_funds'];

export const KYC_INVALID_REASONS = {
  'id': ['document_type', 'incorrect_name', 'visibility', 'expired', 'incorrect_date_of_birth'],
  'address': ['document_type', 'incorrect_name', 'visibility', 'expired', 'incorrect_address'],
  'credit_card': ['incorrect_card_number', 'document_type', 'incorrect_card_holder', 'expired', 'visibility', 'name_visibility'],
  'bank_iban': ['document_type', 'incorrect_name', 'visibility', 'expired', 'incorrect_bank_account_number'],
  'proof_of_deposit': ['document_type', 'visibility', 'expired', 'incorrect_bank_details', 'payment_proof_mismatch_deposit_details'],
  'source_of_funds': ['document_type', 'visibility', 'expired', 'insufficient_information']
};


export const KYC_ACCEPTED = ["status", 'id_type', 'address_type', 'card_type', 'iban_type', 'proof_of_deposit', 'source_of_funds'];

export const REWARD_CONSUMED_STATUS = 'consumed';
export const REWARD_CREATED_STATUS = 'created'
export const REWARD_SOLD_STATUS = 'sold'
export const REWARD_REMOVED_STATUS = 'removed'

export const LOADABLE_BET_HISTORY_PROVIDERS_SLUGS = ['betby'];
