import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { OffsetPagination } from "../shared/OffsetPagination";
import "react-datepicker/dist/react-datepicker.css";
import { utc as moment } from "moment";
import Calendar from "../shared/Calendar";
import Select from "react-select";
import { useSelector } from "react-redux";
import { isDecimalNumber } from "../shared/utils/numbers";
import { downloadFile } from "../shared/utils/files";
import ExportDataWithDateRangeModal from "../shared/export-data-with-date-range-modal";
import ExportDataButton from "../shared/export-data-button";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import styles from "./transactions.module.scss";

const ALL_TYPES_EXCEPT_game_provider_etc = "all except game_provider_*";

const types = [
  "game_provider_bet",
  "game_provider_win",
  "game_provider_refund",
  "withdrawal",
  "withdrawal_refund",
  "deposit",
  "loyalty_reward",
  "bonus",
  "cashback_reward_payout",
  "free_winnings_transfer",
  "bonus_transfer",
  "admin",
  "referral_conversion",
  ALL_TYPES_EXCEPT_game_provider_etc,
];

const subWalletTypes = ["both", "real", "bonus"];

const orderings = [
  { label: "Date - Newest first", value: "created_at_desc" },
  { label: "Date - Oldest first", value: "created_at_asc" },
  { label: "Amount - Highest to lowest", value: "amount_desc" },
  { label: "Amount - Lowest to highest", value: "amount_asc" },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let { id: userId } = useParams();
  const [page, setPage] = useState(1);
  const [transactionId, setTransactionId] = useState(null);
  const [type, setType] = useState(null);
  const [note, setNote] = useState(null);
  const [subWalletType, setSubWalletType] = useState("both");
  const [walletId, setWalletId] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().subtract(45, "days").toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [order, setOrder] = useState(orderings[0].value);
  const [minAmount, setMinAmount] = useState(null);
  const [maxAmount, setMaxAmount] = useState(null);
  const [openExportDataModal, setOpenExportDataModal] = useState(false);
  const [downloadingReport, setDownloadingReport] = useState(false);

  const wallets = useSelector((store) => store.users.user.wallets);

  useEffect(() => {
    setWalletId(wallets.find((w) => w.primary).id);
  }, [wallets]);

  useEffect(() => {
    setPage(1);
  }, [transactionId, type, walletId, startDate, endDate, order, note]);

  const {
    data: { transactions, total_pages },
    isLoading,
  } = useQuery(
    [
      "txs",
      page,
      transactionId,
      type,
      walletId,
      startDate,
      endDate,
      order,
      note,
      subWalletType,
      minAmount,
      maxAmount,
    ],
    () => {
      let realType = type;
      if (type === ALL_TYPES_EXCEPT_game_provider_etc) {
        realType = "all_except_game_provider_etc";
      }
      return window.api.get(`/admin/users/${userId}/transactions`, {
        params: {
          page,
          startDate,
          endDate,
          transactionId,
          type: realType,
          walletId,
          note,
          order,
          subWalletType: subWalletType === "both" ? null : subWalletType,
          minAmount: minAmount || 0,
          maxAmount: maxAmount || null,
        },
      });
    },
    {
      keepPreviousData: true,
      initialData: {
        transactions: [],
        total_pages: 1,
      },
    }
  );

  const downloadReport = (exportTo, startDate, endDate) => {
    setDownloadingReport(true);
    require("axios")({
      url: `/api/admin/users/${userId}/transactions/export/${exportTo}`,
      method: "GET",
      responseType: "blob", // important,
      params: {
        startDate,
        endDate,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "X-RateLimit-Bypass": "1",
      },
    })
      .then((response) => {
        const extension = exportTo === "xls" ? "xlsx" : exportTo;
        downloadFile(response.data, extension);
      })
      .finally(() => setDownloadingReport(false));
  };

  return (
    <div style={{ position: "relative", "min-height": "500px" }}>
      {isLoading && (
        <div className={"loading-overlay"}>
          <h2>Loading, please wait ...</h2>
          <div className="loader" />
        </div>
      )}
      <div className={styles.filtersQuery}>
        <div className={styles.filterQuery}>
          <h5>Search by Time</h5>
          <Calendar
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>
        <div className={styles.filterQuery}>
          <h5>Search by ID</h5>
          <input
            type="text"
            id="id"
            name="id"
            onChange={(e) => {
              setTransactionId(e.target.value);
            }}
          />
        </div>
        <div className={styles.filterQuery}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <h5>Search by Note</h5>

            <button
              className={styles.buttonExport}
              onClick={() => {
                setNote(
                  "Unable to locate bonus journal, payed to real instead"
                );
              }}
            >
              Bonus Into Real
            </button>
          </div>
          <input
            type="text"
            id="note"
            name="note"
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
          />
        </div>
        <div className={styles.filterQuery}>
          <h5>Min amount</h5>
          <input
            type="text"
            id="minAmount"
            name="minAmount"
            value={minAmount}
            onKeyDown={(e) => {
              if (!isDecimalNumber(e.key) && e.key !== "Backspace") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              setMinAmount(e.target.value);
            }}
          />
        </div>
        <div className={styles.filterQuery}>
          <h5>Max amount</h5>
          <input
            type="text"
            id="maxAmount"
            name="maxAmount"
            value={maxAmount}
            onKeyDown={(e) => {
              if (!isDecimalNumber(e.key) && e.key !== "Backspace") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              setMaxAmount(e.target.value);
            }}
          />
        </div>
      </div>
      <div className={styles.filtersQuery}>
        <div className={styles.filterQuery}>
          <h5>Search by sub wallet</h5>
          <Select
            clearable={false}
            name="subWalletType"
            value={subWalletType}
            onChange={(option) => setSubWalletType(option.value)}
            options={subWalletTypes.map((type) => ({
              label: type,
              value: type,
            }))}
          />
        </div>
        <div className={styles.filterQuery}>
          <h5>Search by Tx Type</h5>
          <Select
            clearable={false}
            name="type"
            value={type}
            onChange={(option) => setType(option.value)}
            options={types.map((type) => ({
              label: type,
              value: type,
            }))}
          />
        </div>
        <div className={styles.filterQuery}>
          <h5>Search by Wallet Currency</h5>
          <Select
            clearable={false}
            name="type"
            value={walletId}
            onChange={(option) => setWalletId(option.value)}
            options={wallets.map((wallet) => ({
              label: wallet.deposit_currency_type,
              value: wallet.id,
            }))}
          />
        </div>
        <div className={styles.filterQuery}>
          <h5>Order</h5>
          <Select
            clearable={false}
            name="type"
            value={order}
            onChange={(option) => setOrder(option.value)}
            options={orderings}
          />
        </div>
      </div>

      <div style={{ minHeight: 400 }}>
        <Table>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>ID</Th>
              <Th>Type</Th>
              <Th>Note</Th>
              <Th>Wallet</Th>
              <Th>Sub Wallet</Th>
              <Th>Amount</Th>
              <Th>Closing Balance</Th>
              <Th>Closing Real Balance</Th>
              <Th>Closing Bonus Balance</Th>
            </Tr>
          </Thead>

          <Tbody>
            {transactions.map((r, i) => {
              return (
                <Tr key={i}>
                  <Td>{moment(r.created_at).format("YYYY-MM-DD HH:mm")}</Td>
                  <Td>
                    <Link
                      to={`/casino/game-provider-rounds/${r.game_provider_round_id}`}
                    >
                      {r.id}
                    </Link>
                  </Td>
                  <Td>{r.type}</Td>
                  <Td>{r.note}</Td>
                  <Td>{r.currency_type}</Td>
                  <Td>{r.sub_wallet_type}</Td>
                  <Td>{r.amount}</Td>
                  <Td>
                    {r.currency_symbol}
                    {r.closing_balance}
                  </Td>
                  <Td>
                    {r.currency_symbol}
                    {r.closing_real_balance}
                  </Td>
                  <Td>
                    {r.currency_symbol}
                    {r.closing_bonus_balance}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </div>

      <OffsetPagination
        page={page}
        setPage={setPage}
        totalPages={total_pages}
      />
      <div className={styles.buttons}>
        <ExportDataButton
          styles={{}}
          onClick={() => setOpenExportDataModal(true)}
        />
        <ExportDataWithDateRangeModal
          isOpen={openExportDataModal}
          onClose={() => setOpenExportDataModal(false)}
          downloadingReport={downloadingReport}
          startDate={startDate}
          endDate={endDate}
          onExport={(params) => {
            downloadReport(params.format, params.startDate, params.endDate);
          }}
        ></ExportDataWithDateRangeModal>
      </div>
    </div>
  );
};
