import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updatePromo, createPromo } from "./actions";
import Pagination from '../../shared/pagination';
import Select from "react-select";
import { updateRewards } from "../reward-actions";
import './promo.css';
import { Link } from "react-router-dom";
import { permissionsConstants, PermissionsFragment } from "../../app/permissions-fragment";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

const ranks = ["any", "silver", "bronze", "gold", "platinum", "diamond", "diamond_2", "diamond_3"];
const ranksOptions = ranks.map(
  type => ({
    label: type,
    value: type
  })
);

class PromoCodes extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      order: 'id_desc',
      type: '',
      status: '',
      rank: null,
      specific_rank: null
    }
  }

  componentDidMount() {
    this.update();
    this.props.updateRewards()
  }

  update() {
    this.props.update({
      page: this.state.page,
      search: this.state.search,
      order: this.state.order
    });
  }

  render() {

    return (
      <div id={"promo"}>
        <h3>Promo Codes</h3>

        <PermissionsFragment feature={permissionsConstants.PROMO_CODES_CREATE_CODE}>
          <h3>Create</h3>
          <div className={"create"}>
            <label>Code</label>
            <input value={this.state.code} onChange={ev => this.setState({ code: ev.target.value })} />
            <label>Max uses</label>
            <input type="number" value={this.state.max} onChange={ev => this.setState({ max: ev.target.value })} />
            <label>Min Rank</label>
            <Select
              name="type"
              value={this.state.rank}
              onChange={option => {
                option = option || { value: null };
                this.setState({
                  rank: option.value
                })
              }}
              options={ranksOptions}
            />


            <label>Specific Rank</label>
            <Select
              name="type"
              value={this.state.specific_rank}
              onChange={option => {
                option = option || { value: null };
                this.setState({
                  specific_rank: option.value
                })
              }}
              options={ranksOptions}
            />


            <label>Reward</label>
            <Select
              name="type"
              value={this.state.reward_id}
              onChange={option => {
                option = option || { value: null };
                this.setState({
                  reward_id: option.value
                })
              }}
              options={
                this.props.rewards.map(
                  reward => ({
                    label: <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img style={{ width: '30px' }} src={reward.image_url} />
                      (ID: ${reward.id}) - ${reward.name} (€ ${reward.eur_value})
                    </div>,
                    value: reward.id
                  })
                )
              }
            />

            <button onClick={() => {

              this.props.create({
                code: this.state.code,
                max_uses: this.state.max,
                reward_id: this.state.reward_id,
                required_rank: this.state.rank === 'any' ? null : this.state.rank,
                specific_rank: this.state.specific_rank === 'any' ? null : this.state.specific_rank
              }).then(() => {
                this.update()
              })

            }}>Create</button>

          </div>
        </PermissionsFragment>

        <PermissionsFragment feature={permissionsConstants.PROMO_CODES_VIEW_LIST}>
          <h4>Search (id, code)</h4>
          <input

            name="type"
            value={this.state.search}
            onChange={ev => {
              this.setState({ search: ev.target.value }, () => this.update());
            }}
          />

          <br />
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Code</Th>
                <Th>Claimed</Th>
                <Th>Remaining Uses</Th>
                <Th>Required Rank</Th>
                <Th>Specific Rank</Th>
                <Th>Reward</Th>
              </Tr>
            </Thead>

            <Tbody>
              {
                this.props.records.map((r, i) => {

                  const reward = r.reward || {}

                  return (
                    <Tr key={i}>
                      <Td>{r.id}</Td>
                      <Td>
                        <Link to={`/casino/promo-codes/${r.id}`}>{r.code}</Link>
                      </Td>
                      <Td>{r.total_uses}</Td>
                      <Td>{r.remaining_uses}</Td>
                      <Td>{r.required_rank_type}</Td>
                      <Td>{r.specific_rank_type}</Td>

                      <Td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img style={{ width: '30px' }} src={reward.image_url} />
                          (ID: ${reward.id}) - ${reward.name} (€ ${reward.eur_value})
                        </div>
                      </Td>

                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>


          <Pagination
            total_pages={this.props.meta.total_pages}
            update={page => {
              this.setState({ page }, () => this.update());
            }}
          />
        </PermissionsFragment>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.promo.data,
  rewards: state.rewards.items.records,

});

const mapDispatchToProps = dispatch => ({
  update: props => dispatch(updatePromo(props)),
  updateRewards: () => dispatch(updateRewards()),
  create(props) {
    return dispatch(createPromo(props));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodes);