

import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { permissionsConstants, PermissionsFragment } from "../app/permissions-fragment";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import Pagination from '../shared/pagination';

const CashbackRewardPeriods = (props) => {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [deposits, setDeposits] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchCashbacks = () => {
        setIsLoading(true);
        window.api.get(`/admin/users/${props.match.params.id}/cashback_rewards`, {
            params: {
                page,
            }
        }).then(
            ({ total_pages, records }) => {
                setDeposits(records);
                setTotalPages(total_pages);
            }
        ).finally(() => setIsLoading(false));
    };

    useEffect(fetchCashbacks, [page]);

    const creditDeposit = (id) => {
        window.api.get(`/admin/users/${props.match.params.id}/cashback_rewards/${id}`).then(
            () => {
                toast.success(`Cashback credited`);
                fetchCashbacks();
            }
        );
    };

    const showCreditButton = (r) => r.cashback_reward_status === 'created' && new Date(r.expires_at) < new Date()

    return (
        <div style={{ position: 'relative', 'min-height': '500px' }}>

            {isLoading &&
                <div className={"loading-overlay"}>
                    <h2>Searching, please wait ...</h2>
                    <div className="loader" />
                </div>
            }

            <Table>
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Reward Manifest ID</Th>
                        <Th>Status</Th>
                        <Th>Reward Credited</Th>
                        <Th>Rakeback</Th>
                        <Th>Action</Th>
                        <Th>Created</Th>
                        <Th>Expiry</Th>
                        <Th>Note</Th>
                    </Tr>
                </Thead>

                <Tbody>
                    {
                        deposits.map((r, i) => {
                            return (
                                <Tr key={i}>
                                    <Td>{r.id}</Td>
                                    <Td>{r.reward_id}</Td>
                                    <Td>{r.cashback_reward_status}</Td>
                                    <Td className="good">{!r.amount_credited ? 'insuffient amount' : `${r.amount_credited_currency} ${r.amount_credited}`}</Td>
                                    <Td className="good">{r.rakeback_percentage || 0} %</Td>
                                    <Td>
                                        {showCreditButton(r) && (
                                            <PermissionsFragment feature={permissionsConstants.PROCESS_CASHBACK_REWARDS}>
                                                <button className="small" onClick={() => {
                                                    creditDeposit(r.id);
                                                }}>Credit</button>
                                            </PermissionsFragment>
                                        )}
                                        {!showCreditButton(r) && 'N/A'}
                                    </Td>
                                    <Td>{r.created_at}</Td>
                                    <Td>{r.expires_at}</Td>
                                    <Td>{r.note}</Td>
                                </Tr>
                            )
                        })
                    }
                </Tbody>
            </Table>
            <Pagination
                total_pages={totalPages}
                update={page => {
                    setPage(page);
                }}
            />
        </div>
    )
}

export default CashbackRewardPeriods;