import React, {Component} from 'react';
import { connect } from 'react-redux';
import {getPromo, editPromo} from "./actions";
import './promo.css';
import { withRouter } from "react-router-dom";
import Select from "react-select";
import {updateRewards} from "../reward-actions";
import {permissionsConstants, PermissionsFragment} from "../../app/permissions-fragment";

class PromoCodes extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {
    this.props.update(this.props.match.params.id);
    this.props.updateRewards()
  }

  render() {

    const { promo } = this.props;
    const id = this.props.match.params.id;
    const reward = promo.reward || {};
    return (
      <div id={"promo"}>
        <h3>Promo</h3>

        {
          Object.keys(promo).map(key => {
            const value = promo[key];

            if (key === 'required_rank_type') {
              return (
                <div>
                  <span>required_rank_type: </span>
                  <span>{value || 'any'}</span>
                </div>
              )
            }

            if (typeof value === 'object') return null;
            return (
              <div>
                <span>{key}: </span>
                <span>{value}</span>
              </div>
            );
          })
        }


        <div>
          <span>Reward</span>
          <span>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <img style={{width: '30px'}} src={reward.image_url}/>
              (ID: ${reward.id}) - ${reward.name} (€ ${reward.eur_value})
            </div>
          </span>
        </div>


        <PermissionsFragment feature={permissionsConstants.PROMO_CODES_UPDATE_CODE}>
          <hr />


          <label>Code</label>
          <input value={this.state.code} onChange={ev => this.setState({code: ev.target.value})}/>
          <button onClick={() => {
            this.props.edit(id, {
              code: this.state.code
            })
          }}>Update Code</button>

          <br/>
          <br/>

          <label>Set remaining uses</label>
          <input type="number" value={this.state.max} onChange={ev => this.setState({max: ev.target.value})}/>
          <button onClick={() => {
            this.props.edit(id, {
              remaining_uses: this.state.max
            })
          }}>Update Uses</button>
          <br/>
          <br/>


          <label>Set min rank</label>
          <Select
            name="type"
            value={this.state.rank}
            onChange={option => {
              option = option || { value: null };
              this.setState({
                rank: option.value
              })
            }}
            options={
              ["any", "silver", "bronze", "gold", "platinum", "diamond"].map(
                type => ({
                  label: type,
                  value: type
                })
              )
            }
          />
          <button onClick={() => {
            this.props.edit(id, {
              required_rank: this.state.rank === 'any' ? null: this.state.rank
            })
          }}>Update min rank</button>
          <br/>
          <br/>


          <label>Reward</label>
          <Select
            name="type"
            value={this.state.reward_id}
            onChange={option => {
              option = option || { value: null };
              this.setState({
                reward_id: option.value
              })
            }}
            options={
              this.props.rewards.map(
                reward => ({
                  label: <div style={{display: 'flex', alignItems: 'center'}}>
                    <img style={{width: '30px'}} src={reward.image_url}/>
                    (ID: ${reward.id}) - ${reward.name} (€ ${reward.eur_value})
                  </div>,
                  value: reward.id
                })
              )
            }
          />

          <button onClick={() => {
            this.props.edit(id, {
              reward_id: this.state.reward_id
            })
          }}>Update reward</button>
        </PermissionsFragment>


      </div>
    )
  }
}

const mapStateToProps = state => ({
  promo: state.promo.selected,
  rewards: state.rewards.items.records,

});

const mapDispatchToProps = dispatch => ({
  update: id => dispatch(getPromo(id)),
  edit: (id, props) => dispatch(editPromo(id, props)),
  updateRewards: () => dispatch(updateRewards()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromoCodes));