import moment from 'moment';

const FORMAT_PATTERN_WITH_SECONDS = 'YYYY-MM-DD HH:mm:ss';
const FORMAT_PATTERN_ONLY_DATE = 'YYYY-MM-DD';
const FORMAT_PATTERN_WITH_MINUTES = 'YYYY-MM-DD HH:mm';

function withSeconds(date) {
  return moment(date).format(FORMAT_PATTERN_WITH_SECONDS);
}

function withMinutes(date) {
  return moment(date).format(FORMAT_PATTERN_WITH_MINUTES);
}

function onlyDate(date) {
  return moment(date).format(FORMAT_PATTERN_ONLY_DATE);
}

export const format = {
  withSeconds,
  withMinutes,
  onlyDate,
};
