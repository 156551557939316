import { Component } from 'react';
import Modal from "react-modal";
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import Pagination from "../shared/pagination";
import {
  createKYCVerification,
  getDocument,
  updateKYCVerification
} from "../user/actions";
import './withdrawals/style.css';

class KYCVerifications extends Component {


  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      order: 'id_desc',
      type: '',
      status: '',
      records: [],
      meta: {}
    }
  }

  componentDidMount() {
    this.update();
  }

  renderDocument() {

    const document = this.props.document;
    if (document.type === 'image') {
      return <img src={document.data} alt="" />;
    } else if (document.type === 'pdf') {
      return <iframe src={document.data} type="application/pdf" style={{
        width: "100%",
        height: "100%",
        overflow: "auto"
      }} />
      return <a href={document.data}>Click to open pdf</a>;
    }
  }


  update() {
    window.api.get(`/admin/kyc_verifications?page=${this.state.page}`).then(data => {
      this.setState({
        ...data
      })
    })
  }

  updateKYCVerification(verification_id, status) {
    window.api.post('/admin/kyc_verifications/update', { verification_id, status })
      .then(() => {
        this.update();
      });

  }

  render() {
    return (
      <div className="">

        <Modal
          overlayClassName={"document-modal"}
          isOpen={this.state.document_modal_open}
          onRequestClose={
            e => this.setState({ document_modal_open: false })
          }
        >
          <div
            onClick={
              e => this.setState({ document_modal_open: false })
            }
            className="close" style={{ fontSize: "20px" }}>Close</div>
          {this.renderDocument()}
        </Modal>

        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>User</Th>
              <Th>Masked Account</Th>
              <Th>Type</Th>
              <Th>SubType</Th>
              <Th>Status</Th>
              <Th>Note</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(this.state.records || []).map((verification, index) => {

              return (
                <Tr key={index}>
                  <Td>{verification.id}</Td>
                  <Td className="user">
                    <Link to={`/users/${verification.user.id}/documents`}>
                      {verification.user.username}
                    </Link>
                  </Td>
                  <Td>{verification.masked_account}</Td>
                  <Td>{verification.type}</Td>
                  <Td>{verification.sub_type}</Td>
                  <Td>{verification.status}</Td>
                  <Td>{verification.note}</Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>


        <Pagination
          total_pages={this.state.meta.total_pages}
          update={page => {
            this.setState({ page }, () => this.update());
          }}
        />

      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.users.user

});

const mapDispatchToProps = dispatch => ({
  getDocument: (user_id, document) => dispatch(getDocument(user_id, document)),


  createKYCVerification: (user_id, type, masked_account) => {
    dispatch(createKYCVerification(user_id, type, masked_account))
  },

  updateKYCVerification: (verification_id, status, user_id) => {
    dispatch(updateKYCVerification(verification_id, status, user_id))
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(KYCVerifications)
);