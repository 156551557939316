import React from 'react';
import Pagination from 'react-paginate';

import './pagination.style.css';
export default props => {

  if (props.total_pages <= 1) {
    return null;
  }

  return (
    <Pagination
      pageCount={props.total_pages}
      onPageChange={page => {
        props.update(page.selected + 1);
      }}
      containerClassName={'pagination'}
      activeClassName={'selected'}
    />
  )
}