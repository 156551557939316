export const CHANGE_DATE_BIRTH = "CHANGE_DATE_BIRTH";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_PHONE = "CHANGE_PHONE";
export const CHANGE_FIRSTNAME = "CHANGE_FIRSTNAME";
export const CHANGE_LASTNAME = "CHANGE_LASTNAME";
export const CHANGE_ADDRESS = "CHANGE_ADDRESS";
export const CHANGE_COUNTRY = "CHANGE_COUNTRY";
export const CHANGE_KYC_COUNTRY = "CHANGE_KYC_COUNTRY";
export const CHANGE_CITY = "CHANGE_CITY";
export const CHANGE_STATE = "CHANGE_STATE";
export const CHANGE_POSTAL_CODE = "CHANGE_POSTAL_CODE";
export const CHANGE_TAX_ID = "CHANGE_TAX_ID";
export const CHANGE_CUSTOMER = "CHANGE_CUSTOMER";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const CHANGE_KYC_LEVEL = "CHANGE_KYC_LEVEL";
