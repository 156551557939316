import { useState } from "react";
import styles from "./styles.module.scss";
import { closeAccount } from "user/actions";
import { connect } from "react-redux";

const ReopenAccountForm = ({ user_id, closeAccount }) => {
  const [reason, setReason] = useState('');

  const reopenAccountAction = async (event) => {
    event.preventDefault();
    if (!reason) {
      alert("The reson field is required!");
      return;
    }
    closeAccount(user_id, true, reason.trim());
  };

  return (
    <>
      <h4>Reopen Account</h4>
      <div className={styles.container}>
        <div className={styles.inputsContainer}>

            <div className={styles.inputComment}>
              <label>Reason</label>
              <div>
                <input
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>
            </div>
        </div>

        <div className={styles.buttonConfirm}>
          <button onClick={reopenAccountAction}>Reopen Account</button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  closeAccount(user_id, remove, reason) {
    dispatch(closeAccount(user_id, remove, reason));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReopenAccountForm);