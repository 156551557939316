import Select from "react-select";
import { useState } from "react";
import styles from "./styles.module.scss";
import { closeAccount } from "user/actions";
import { connect } from "react-redux";

const reasonsOptions = [
  { id: 1, reason: "Bug/Tech issues", comment: false },
  { id: 2, reason: "Fraud/Abuse", comment: false },
  { id: 3, reason: "Gambling Addiction", comment: false },
  { id: 4, reason: "Minor", comment: true },
  { id: 5, reason: "Permanent Request", comment: false },
  { id: 6, reason: "User request", comment: false },
  { id: 7, reason: "Multiples Account", comment: false },
  { id: 8, reason: "Hacker victim", comment: false },
];

const CloseAccountForm = ({ user_id, closeAccount }) => {
  const [reason, setReason] = useState(null);
  const [comment, setComment] = useState("");
  const [showInputComment, setShowInputComment] = useState(false);

  const handlerSelectChange = (elem) => {
    if (elem) {
      const optionSelected = reasonsOptions.find(({ id }) => id == elem.value);
      setReason({
        label: optionSelected.reason,
        value: optionSelected.id,
      });
      if (optionSelected.comment) {
        setShowInputComment(true);
      } else {
        setShowInputComment(false);
        setComment("");
      }
    } else {
      setReason(null);
      setShowInputComment(false);
      setComment("");
    }
  };

  const closeAccountAction = async (event) => {
    event.preventDefault();
    if (!reason) {
      alert("Must select a reson!");
      return;
    }
    closeAccount(user_id, false, `${reason.label}. ${comment}`.trim());
  };

  return (
    <>
      <h4>Close Account</h4>
      <div className={styles.container}>
        <div className={styles.inputsContainer}>
          <div className={styles.selectReason}>
            <label>Select a reason</label>
            <Select
              placeholder="Reason"
              name="reason"
              default={null}
              value={reason}
              onChange={handlerSelectChange}
              options={reasonsOptions.map(({ reason, id }) => {
                return {
                  label: reason,
                  value: id,
                };
              })}
            />
          </div>

          {showInputComment ? (
            <div className={styles.inputComment}>
              <label>Comment</label>
              <div>
                <input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
            </div>
          ) : null}
        </div>

        <div className={styles.buttonConfirm}>
          <button onClick={closeAccountAction}>Close Account</button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  closeAccount(user_id, remove, reason) {
    dispatch(closeAccount(user_id, remove, reason));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CloseAccountForm);
