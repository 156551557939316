const GAMES_BUT_NOT_AFFILIATE_LINKS = [
  "GAMES_VIEW_GAME",
  "GAMES_VIEW_LIST",
  "GAMES_ENABLE_GAME",
  "GAMES_DISABLE_GAME",

  "GAME_PROVIDERS_ENABLE_PROVIDER",
  "GAME_PROVIDERS_DISABLE_PROVIDER",
  "GAME_PROVIDERS_VIEW_LIST",
  "GAME_PROVIDERS_VIEW_PROVIDER",

  "GAME_CATEGORIES_VIEW_LIST",
  "GAME_CATEGORIES_VIEW_CATEGORY",
  "GAME_CATEGORIES_CREATE_CATEGORY",
  "GAME_CATEGORIES_ADD_GAME",
  "GAME_CATEGORIES_REMOVE_GAME",
  "GAME_CATEGORIES_UPDATE_ORDINALITY",
];

module.exports = {
  // in the future, lets do it like below
  SYSTEM: {
    SETTINGS: require("../system/permissions"),
    GAMING_SERVICE_SETTINGS: [
      "GAMING_SERVICE_SETTINGS_VIEW_LIST",
      "GAMING_SERVICE_SETTINGS_UPDATE",
    ],
    WAITLIST_MANAGEMENT: [
      "WAITLIST_FEATURE_BULK_GRANT_ACCESS",
      "WAITLIST_FEATURE_CHANGE_USER_STATUS",
      "WAITLIST_FEATURE_UPDATE_STATUS",
      "WAITLIST_FEATURE_VIEW",
      "WAITLIST_FEATURE_VIEW_LIST",
    ],
    GROUPS: [
      "FLAG_GROUP_CREATE",
      "FLAG_GROUP_UPDATE",
      "FLAG_GROUP_LIST",
      "FLAG_GROUP_LIST_USERS",
      "FLAG_GROUP_UPDATE_USER",
      "FLAG_GROUP_CREATE_USER",
      "ADD_USERS_TO_GROUP",
      "SET_USERS_TO_GROUP",
      "REMOVE_FEATURE_FLAG_GROUP_FROM_USER",
      "REMOVE_FEATURE_FLAG_GROUPS_FROM_USERS:",
      "SET_GROUPS_TO_USER",
      "SET_GROUPS_TO_USERS",
      "SET_ENABLED_USER_FROM_GROUP",
      "DELETE_BULK_USERS_FROM_GROU",
      "ADD_GROUPS_TO_USER",
    ],
    RAFFLE_MANAGER: [
      "RAFFLE_CALCULATE_TOTAL_TICKETS",
      "RAFFLE_CALCULATE_WINNING_USER",
      "RAFFLE_VIEW_LIST",
    ],
    CUSTOM_ANALYTICS: ["VIEW_CUSTOM_ANALYTICS", "VIEW_CUSTOM_ANALYTICS_ACTION_TYPES"],
    STAFF_MANAGER: [
      'APPROVE_REQUEST',
      'DENY_REQUEST',
      'VIEW_REQUESTS'
    ]
  },

  MARKETING: {
    ANNOUNCEMENTS: [
      "ANNOUNCEMENTS_VIEW_LIST",
      "ANNOUNCEMENTS_CREATE",
      "ANNOUNCEMENTS_VIEW",
    ],
    AFFILIATE_LINKS: [
      "AFFILIATES_VIEW_LIST",
      "AFFILIATES_CREATE_LINK",
      "AFFILIATES_VIEW_LINK",
    ],
    REFERRAL_CODES: ["REPORTS_TOP_REFERRALS"],
  },

  REWARDS: {
    GENERAL: [
      "REWARDS_VIEW_LIST",
      "REWARDS_VIEW_REWARD",
      "REWARDS_CREATE_REWARD",
      "REWARDS_UPDATE_REWARD",
      "PROCESS_CASHBACK_REWARDS",
    ],
    MYSTERY_BOXES: [
      "MYSTERY_BOXES_VIEW_LIST",
      "MYSTERY_BOXES_CALCULATE_PRICE",
      "MYSTERY_BOXES_CREATE_BOX",
      "MYSTERY_BOXES_VIEW_BOX",
    ],
    REWARD_TIERS: [
      "MYSTERY_BOXES_REWARD_TIERS_VIEW_LIST",
      "MYSTERY_BOXES_REWARD_TIERS_VIEW_TIER",
      "MYSTERY_BOXES_REWARD_TIERS_CREATE_TIER",
      "MYSTERY_BOXES_REWARD_TIERS_UPDATE_TIER",
    ],
    USER_RANKS: ["RANKS_VIEW_LIST", "RANKS_UPDATE_RANK"],
    GLOBAL_XP: [
      "GLOBAL_XP_VIEW_BONUS",
      "GLOBAL_XP_UPDATE_BONUS",
      "GLOBAL_XP_DISABLE_BONUS",
    ],

    PROMO_CODES: [
      "PROMO_CODES_VIEW_LIST",
      "PROMO_CODES_CREATE_CODE",
      "PROMO_CODES_VIEW_LIST_REDEEMS",
      "PROMO_CODES_VIEW_CODE",
      "PROMO_CODES_UPDATE_CODE",
    ],
    BONUSES: [
      "BONUSES_VIEW_LIST",
      "BONUSES_CREATE_BONUS",
      "BONUSES_UPDATE_BONUS",
      "BONUSES_VIEW_BONUS",
      "BONUS_ROUND_WELCOME_PACKAGE_VIEW_LIST",
      "BONUS_ROUND_WELCOME_PACKAGE_CREATE",
      "BONUS_ROUND_WELCOME_PACKAGE_UPDATE",
    ],
    RACES: [
      "RACES_VIEW_LIST",
      "RACE_PRIZES_VIEW_LIST",
      "RACES_CREATE_RACE",
      "RACES_VIEW_RACE",
    ],
    SUPPORTED_CURRENCIES: ["SUPPORTED_CURRENCIES_VIEW_LIST"],
    CHALLENGES: ["CHALLENGES_VIEW_LIST", "CHALLENGES_CREATE_RACE"],
    BONUS_WELCOME_PACKAGE: [
      "BONUS_ROUND_WELCOME_PACKAGE_VIEW_LIST",
      "BONUS_ROUND_WELCOME_PACKAGE_CREATE",
      "BONUS_ROUND_WELCOME_PACKAGE_UPDATE",
    ],
    LOYALTY: ["LOYALTY_VIEW_LIST"],
  },

  USERS: {
    GENERAL: ["USER_VIEW_LIST", "USER_VIEW_USER"],
    INFO: [
      "USER_VIEW_USER",
      "USER_UPDATE_EMAIL",
      "USER_UPDATE_KYC_INFO",
      "USER_UPDATE",
      "USER_UPDATE_SUSPENSION",
      "USER_CANCEL_SUSPENSION",
      "USER_UPDATE_BAN",
    ],
    KYC: [
      "USER_KYC_VIEW_LIST",
      "USER_KYC_UPLOAD_FILE",
      "USER_KYC_CREATE_VERIFICATION",
      "USER_KYC_UPDATE_VERIFICATION",
      "USER_KYC_SEND_NOTIFICATION",
      "USER_KYC_SEND_NOTIFICATION",
      "USER_DOCUMENTS_VIEW_LIST",
      "USER_DOCUMENTS_VIEW_FILE",
    ],
    DOCUMENTS: ["USER_DOCUMENTS_VIEW_LIST", "USER_DOCUMENTS_VIEW_FILE"],
    UPDATE_EMAIL: ["USER_UPDATE_EMAIL", "REQUEST_USER_UPDATE_EMAIL"],
    BALANCES: ["USER_VIEW_USER", "USER_UPDATE_WALLET_BALANCE"],
    UPDATE_BALANCES: [
      "REQUEST_USER_UPDATE_WALLET_BALANCE",
      "USER_UPDATE_WALLET_BALANCE",
      "REQUEST_REFUND",
    ],
    TRANSACTIONS: ["USER_TRANSACTIONS_VIEW_LIST"],
    ROLES: [
      "USER_CLOSE_ACCOUNT",
      "USER_UPDATE_RANK",
      "USER_UPDATE_ROLES",
      "USER_UPDATE_LABEL",
      "USER_ADD_ROLE",
      "USER_REMOVE_ROLE",
    ],
    PERMISSIONS: [
      "USER_VIEW_LIST_PERMISSIONS",
      "USER_UPDATE_PERMISSIONS",
      "USER_REMOVE_PERMISSIONS",
    ],
    FINGERPRINTS: ["USER_FINGERPRINTS_VIEW_LIST"],
    MUTUAL: ["USER_FINGERPRINTS_VIEW_LIST"],
    BONUSES: [
      "USER_VIEW_USER",
      "BONUSES_VIEW_LIST",
      "USER_BONUSES_ADD_BONUS",
      "USER_BONUSES_UPDATE_ACTIVATION",
    ],
    DEPOSITS: ["USER_DEPOSITS_VIEW_LIST"],
    BETS: ["USER_VIEW_CRASH_BETS", "USER_VIEW_ROULETTE_BETS"],
    NOTES: ["USER_NOTES_VIEW_LIST", "USER_NOTES_CREATE"],
    WITHDRAWALS: ["USER_WITHDRAWALS_VIEW_LIST"],
    BONUS_ROUNDS: [
      "USER_BONUS_ROUNDS_VIEW_LIST",
      "USER_BONUS_ROUNDS_ADD_ROUNDS",
      "USER_BONUS_ROUNDS_TRANSACTIONS_VIEW_LIST",
    ],
    MYSTERY_BOX_BALANCES: [
      "USER_MYSTERY_BOXES_VIEW_LIST_OPENSUSER_MYSTERY_BOXES_VIEW_LIST_TRANSACTIONS",
      "USER_MYSTERY_BOXES_ADD_SPINS",
      "USER_MYSTERY_BOXES_VIEW_LIST_BALANCES",
    ],
    MYSTERY_BOX_OPENS: [
      "USER_MYSTERY_BOXES_VIEW_LIST_OPENSUSER_MYSTERY_BOXES_VIEW_LIST_TRANSACTIONS",
      "USER_MYSTERY_BOXES_ADD_SPINS",
      "USER_MYSTERY_BOXES_VIEW_LIST_BALANCES",
    ],
    REWARDS: [
      "USER_REWARDS_INVENTORY_VIEW_LIST",
      "USER_REWARDS_INVENTORY_ADD_REWARD",
      "USER_REWARDS_INVENTORY_UPDATE_REWARD",
      "VIEW_CASHBACK_REWARD_DETAILS",
    ],
    REFERRALS: ["USER_REFERRAL_VIEW"],
    LOYALTY: ["USER_LOYALTY_VIEW_LIST"],
    GAMES: ["USER_GAME_PROVIDER_ROUNDS_VIEW_LIST"],
    SOFTSWISS_FREE_SPINS: ["SOFTSWISS_FREE_SPINS_VIEW_LIST"],
    PAYMENT_METHODS: [
      "PAYMENT_METHODS_USER_BLACKLISTS_VIEW",
      "PAYMENT_METHODS_USER_BLACKLISTS_CREATE",
      "PAYMENT_METHODS_USER_BLACKLISTS_DISABLE",
    ],
    OVERDRAFTS: ["OVERDRAFT_ALERTS_USER_LIST_VIEW"],
    OTP: ["USER_OTP_VIEW_LIST", "USER_OTP_DELETE_PHONE"],
  },

  BETS: {
    USER_VIEW_GAME_PROVIDER_ROUNDS: ["USER_VIEW_GAME_PROVIDER_ROUNDS"],
    GAMES: [...GAMES_BUT_NOT_AFFILIATE_LINKS, "AFFILIATES_CREATE_LINK"],
    ONLY_GAMES: GAMES_BUT_NOT_AFFILIATE_LINKS,
  },
  GAMES: {
    GAME_PROVIDERS: [
      "GAME_PROVIDERS_ENABLE_PROVIDER",
      "GAME_PROVIDERS_DISABLE_PROVIDER",
      "GAME_PROVIDERS_VIEW_LIST",
      "GAME_PROVIDERS_VIEW_PROVIDER",
      "GAME_PROVIDER_VIEW"
    ],
    GAME_CATEGORIES: [
      "GAME_CATEGORIES_VIEW_LIST",
      "GAME_CATEGORIES_VIEW_CATEGORY",
      "GAME_CATEGORIES_CREATE_CATEGORY",
      "GAME_CATEGORIES_ADD_GAME",
      "GAME_CATEGORIES_REMOVE_GAME",
      "GAME_CATEGORIES_UPDATE_ORDINALITY",
    ]
  },
  PAYMENTS: {
    DEPOSITS: [
      "DEPOSITS_VIEW_LIST",
      "DEPOSITS_CREDIT_DEPOSIT",
      "DEPOSITS_CREDIT_DEPOSIT_BULK",
    ],
    WITHDRAWALS: [
      "WITHDRAWALS_VIEW_LIST",
      "WITHDRAWALS_VIEW_WITHDRAWAL",
      "WITHDRAWALS_KYC_RISK_VALIDATION",
      "WITHDRAWALS_CREATE_FRAUD_CHECK",
      "WITHDRAWALS_UPDATE_WITHDRAWAL",
      "WITHDRAWALS_RETRY_WITHDRAWAL",
      "WITHDRAWALS_VIEW_REFUNDABLE_DEPOSITS_LIST",
      "WITHDRAWALS_REFUND_DEPOSIT_FOR_WITHDRAWAL",
      "WITHDRAWALS_VIEW_LITE"
    ],
    WITHDRAWALS_FULL_VIEW: [
      "WITHDRAWALS_VIEW_LIST",
      "WITHDRAWALS_VIEW_WITHDRAWAL",
      "WITHDRAWALS_KYC_RISK_VALIDATION",
      "WITHDRAWALS_CREATE_FRAUD_CHECK",
      "WITHDRAWALS_UPDATE_WITHDRAWAL",
      "WITHDRAWALS_RETRY_WITHDRAWAL",
      "WITHDRAWALS_VIEW_REFUNDABLE_DEPOSITS_LIST",
      "WITHDRAWALS_REFUND_DEPOSIT_FOR_WITHDRAWAL",
    ],
    PIX_WITHDRAWALS: ["WITHDRAWALS_VIEW_PIX_LIST"],
    STUCK_WITHDRAWALS: ["WITHDRAWALS_VIEW_STUCK_LIST"],
    AUTOMATED_WITHDRAWALS: ["WITHDRAWALS_VIEW_AUTOMATED_LIST"],
    FRAUD_CHECKS: ["KYC_VIEW_LIST", "KYC_UPDATE_VERIFICATION"],
    KYC_VERIFICATIONS: ["KYC_VIEW_LIST", "KYC_UPDATE_VERIFICATION"],
    METHODS: ["PAYMENT_METHODS_VIEW_LIST", "PAYMENT_PLATFORMS_UPDATE_METHOD"],
    PROVIDERS: [
      "PAYMENT_PROVIDERS_VIEW_LIST",
      "PAYMENT_PROVIDERS_VIEW_PROVIDER",
      "PAYMENT_PROVIDERS_UPDATE_PROVIDER",
      "PAYMENT_PROVIDERS_VIEW_PROVIDER_RULES",
    ],
    COUNTRY_ORDERINGS: [
      "PAYMENT_COUNTRY_ORDERING_VIEW_LIST",
      "PAYMENT_COUNTRY_ORDERING_CREATE_ORDERING",
      "PAYMENT_COUNTRY_ORDERING_VIEW_ORDERING",
      "PAYMENT_COUNTRY_ORDERING_UPDATE_ORDERING",
    ],
    ACCOUNTS: ["PAYMENT_ACCOUNTS_CREATE_INFLUENCER_CARD"],
    GIFT_CARDS: ["GIFT_CARDS_CREATE_BULK"],
  },

  STAFF_MANAGER: {
    REQUESTS: [
      'APPROVE_REQUEST',
      'DENY_REQUEST',
      'VIEW_REQUESTS',
    ]
  },
  BLAZE_ORIGINALS: {
    CRASH: ["VIEW_CRASH_BET"],
    DOUBLE: ["VIEW_DOUBLE_BET"],
  },
  BIG_WINS: {
    GENERAL: ["VIEW_BIG_WINS"],
  },
  SUPPORTED_CURRENCIES: {
    GENERAL: ["VIEW_SUPPORTED_CURRENCIES"],
  },
  AFFILIATES: {
    GENERAL: [
      "AFFILIATES_VIEW_LIST",
      "AFFILIATES_VIEW_AFFILIATE",
      "AFFILIATES_VIEW_SYSTEM",
      "AFFILIATES_SET_MANAGER",
    ],
    INFO: ["AFFILIATES_VIEW_AFFILIATE"],
    REFERRALS: ["AFFILIATES_REFERRAL_VIEW"],
    CAMPAIGNS: [
      "AFFILIATES_CAMPAIGNS_VIEW",
      "AFFILIATES_CAMPAIGNS_CREATE",
      "AFFILIATES_CAMPAIGNS_UPDATE",
    ],
    BALANCES: ["AFFILIATES_VIEW_AFFILIATE"],
  },
  MONITORING: {
    GENERAL: ["STATUS_MONITORING"],
  },
};
