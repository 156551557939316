import {
  permissionGroups,
  GroupPermissionsFragment,
  permissionsConstants,
  PermissionsFragment,
} from "app/permissions-fragment";

const USERS = permissionGroups.USERS;

export const linksConfigUser = [
  {
    to: "/info",
    props: { group: USERS.INFO },
    label: "User Info",
    component: GroupPermissionsFragment,
  },
  {
    to: "/documents",
    props: { group: USERS.KYC },
    label: "Documents",
    component: GroupPermissionsFragment,
  },
  {
    to: "/anti-fraud",
    props: { group: USERS.MUTUAL },
    label: "Anti-Fraud",
    component: GroupPermissionsFragment,
  },
  {
    to: "/roles",
    props: { group: USERS.ROLES },
    label: "Roles",
    component: GroupPermissionsFragment,
  },
  {
    to: "/fingerprints",
    props: { group: USERS.FINGERPRINTS },
    label: "Fingerprints",
    component: GroupPermissionsFragment,
  },
  {
    to: "/notes",
    props: { group: USERS.NOTES },
    label: "Notes",
    component: GroupPermissionsFragment,
  },
  {
    to: "/route-permissions",
    props: { group: USERS.ROLES.filter(r => r !== 'USER_UPDATE_ROLES') }, // workarround to avoid support_manager set specific permissions
    label: "Route Permissions",
    component: GroupPermissionsFragment,
  },
  {
    to: "/overdraft-alerts",
    props: { group: USERS.OVERDRAFTS },
    label: "Overdraft Alerts",
    component: GroupPermissionsFragment,
  },
  {
    to: "/permissions",
    props: { group: USERS.PERMISSIONS },
    label: "Permissions",
    component: GroupPermissionsFragment,
  },
];
const linksConfigFinancial = [
  {
    to: "/transactions",
    props: { group: USERS.TRANSACTIONS },
    label: "Transactions",
    component: GroupPermissionsFragment,
  },
  {
    to: "/financials",
    props: { feature: permissionsConstants.USER_GET_FINANCIALS },
    label: "Financials",
    component: PermissionsFragment,
  },
  {
    to: "/deposits",
    props: { group: USERS.DEPOSITS },
    label: "Deposits",
    component: GroupPermissionsFragment,
  },
  {
    to: "/withdrawals",
    props: { group: USERS.WITHDRAWALS },
    label: "Withdrawals",
    component: GroupPermissionsFragment,
  },
  {
    to: "/payment-methods",
    props: { group: USERS.PAYMENT_METHODS },
    label: "Payment Methods",
    component: GroupPermissionsFragment,
  },
  {
    to: "/otp",
    props: { group: USERS.OTP },
    label: "OTP",
    component: GroupPermissionsFragment,
  }
];

const linksConfigGameBets = [
  {
    to: "/game-provider-rounds",
    props: { group: USERS.BETS },
    label: "Game History",
    component: GroupPermissionsFragment,
  },
  {
    to: "/bets-pending",
    props: { group: USERS.BETS },
    label: "Pending Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/crash-bets",
    props: { group: USERS.BETS },
    label: "Crash Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/crash-II-bets",
    props: { group: USERS.BETS },
    label: "Crash II",
    component: GroupPermissionsFragment,
  },
  {
    to: "/neymar-crash-bets",
    props: { group: USERS.BETS },
    label: "Neymar Crash Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/crash-rewired-bets",
    props: { group: USERS.BETS },
    label: "Crash Rewired Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/double-bets",
    props: { group: USERS.BETS },
    label: "Double Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/double-v2-bets",
    props: { group: USERS.BETS },
    label: "Double V2 Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/mines-bets",
    props: { group: USERS.BETS },
    label: "Mines Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/mines-bets-v2",
    props: { group: USERS.BETS },
    label: "Mines Bets V2",
    component: GroupPermissionsFragment,
  },
  {
    to: "/tower-bets",
    props: { group: USERS.BETS },
    label: "Tower Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/coin-flip-bets",
    props: { group: USERS.BETS },
    label: "CoinFlip Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/dice-bets",
    props: { group: USERS.BETS },
    label: "Dice Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/plinko-bets",
    props: { group: USERS.BETS },
    label: "Plinko Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/limbo-bets",
    props: { group: USERS.BETS },
    label: "Limbo Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/slide-bets",
    props: { group: USERS.BETS },
    label: "Slide Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/fortune-double-bets",
    props: { group: USERS.BETS },
    label: "Fortune Double Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/fruits-slice-bets",
    props: { group: USERS.BETS },
    label: "Fruits Slice Bets",
    component: GroupPermissionsFragment,
  },
  {
    to: "/fruits-bets",
    props: { group: USERS.BETS },
    label: "Fruits Bets",
    component: GroupPermissionsFragment,
  }
];

const linksConfigPrizeFreeMoney = [{
  to: "/bonuses",
  props: { feature: permissionsConstants.BONUSES_VIEW_BONUS },
  label: "Bonuses",
  component: PermissionsFragment,
},
{
  to: "/bonus-rounds",
  props: { group: USERS.BONUS_ROUNDS },
  label: "Bonus Rounds",
  component: GroupPermissionsFragment,
},
{
  to: "/softswiss-free-spins",
  props: { group: USERS.SOFTSWISS_FREE_SPINS },
  label: "Softswiss Free Spins",
  component: GroupPermissionsFragment,
},
{
  to: "/mystery-boxes",
  props: { group: USERS.MYSTERY_BOX_BALANCES },
  label: "Mystery Box Balances",
  component: GroupPermissionsFragment,
},
{
  to: "/mystery-box-opens",
  props: { group: USERS.MYSTERY_BOX_OPENS },
  label: "Mystery Box Opens",
  component: GroupPermissionsFragment,
},
{
  to: "/reward-inventory",
  props: { group: USERS.REWARDS },
  label: "Reward Inventory",
  component: GroupPermissionsFragment,
},
{
  to: "/cashback-reward-periods",
  props: { group: USERS.REWARDS },
  label: "Cashback Rewards",
  component: GroupPermissionsFragment,
},
{
  to: "/referrals",
  props: { group: USERS.REFERRALS },
  label: "Referrals",
  component: GroupPermissionsFragment,
},
{
  to: "/loyalty",
  props: { group: USERS.LOYALTY },
  label: "Loyalty",
  component: GroupPermissionsFragment,
}
];

const linksConfig = {
  general: linksConfigUser.concat(linksConfigFinancial),
  gameBets: linksConfigGameBets,
  prizeFreeMoney: linksConfigPrizeFreeMoney,
  mobileView: linksConfigUser.concat(linksConfigFinancial, linksConfigGameBets, linksConfigPrizeFreeMoney)
};

export default linksConfig;