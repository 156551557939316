import React, { Component } from 'react';
import Pagination from '../shared/pagination';

import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from '../shared/utils/dates';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import bettingApi from 'app/http-betting-api';

export default class UserDoubleBets extends Component {
   constructor(props) {
      super(props);

      this.state = {
         page: 1,
         total_pages: 0,
         bets: [],
         queryFilter: {},
         hasChange: false,
         gameStartedAtStart: null,
         gameStartedAtEnd: null,
         gameSettledAtStart: null,
         gameSettledAtEnd: null,
         joinDateStarted: null,
         lastDepositStarted: null,
         joinDateSettled: null,
         lastDepositSettled: null,
      };
   }

   componentDidMount() {
      this.fetch();
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevState.page !== this.state.page) {
         this.fetch();
      }
   }

   async fetch() {
      this.setState({ isLoading: true });
      try {
         const { total_pages, records } = await bettingApi.get(
            `/admin/users/${this.props.match.params.id}/double-bets?page=${this.state.page}`,
         );
         this.setState({ total_pages, bets: records });
      } catch (err) {
         toast.error(err.message);
      }
      this.setState({ isLoading: false });
   }

   async rollback(id) {
      try {
         await window.api.post(`/admin/roulette_bets/${id}`);
         toast.success('Bet rolled back');
      } catch (err) {
         toast.error('Bet already rolled back.');
      }

      this.fetch();
   }

   render() {
      return (
         <div style={{ position: 'relative', 'min-height': '500px' }}>
            {this.state.isLoading && (
               <div className={'loading-overlay'}>
                  <h2>Loading, please wait ...</h2>
                  <div className='loader' />
               </div>
            )}
            <Table>
               <Thead>
                  <Tr>
                     <Th>Round ID</Th>
                     <Th>Bet ID</Th>
                     <Th>Game Started At</Th>
                     <Th>Game Settled At</Th>
                     <Th>Bet Placed At</Th>
                     <Th>Bet Settled At</Th>
                     <Th>Amount</Th>
                     <Th>Bet Color</Th>
                     <Th>Winning Color</Th>
                     <Th>Free</Th>
                     <Th>Winnings</Th>
                     <Th>Status</Th>
                     <Th>Profit</Th>
                  </Tr>
               </Thead>

               <Tbody>
                  {this.state.bets.map((r, i) => {
                     return (
                        <Tr key={i}>
                           <Td>
                              {r.free_bet ? (
                                 <span>{r.round_id}</span>
                              ) : (
                                 <Link to={`/casino/game-provider-rounds/${r.remote_round_id}`}>
                                    {r.remote_round_id}
                                 </Link>
                              )}
                           </Td>
                           <Td>{r.round_id}</Td>
                           <Td>{format.withSeconds(r.game_started_at)}</Td>
                           <Td>{format.withSeconds(r.game_settled_at)}</Td>
                           <Td>{format.withSeconds(r.bet_placed_at)}</Td>
                           <Td>{format.withSeconds(r.bet_settled_at)}</Td>
                           <Td className={r.profit > 0 ? 'good' : 'bad'}>
                              {r.amount} {r.currency_type}
                           </Td>
                           <Td>{r.bet_color}</Td>
                           <Td>{r.winning_color}</Td>
                           <Td>{r.free_bet ? 'Yes' : 'No'}</Td>
                           <Td className={r.profit > 0 ? 'good' : 'bad'}>
                              {r.win_amount} {r.currency_type}
                           </Td>
                           <Td>{r.status}</Td>
                           <Td className={r.profit >= 0 ? 'good' : 'bad'}>
                              {r.profit} {r.currency_type}
                           </Td>
                        </Tr>
                     );
                  })}
               </Tbody>
            </Table>

            <Pagination
               total_pages={this.state.total_pages}
               update={(page) => {
                  this.setState({ page });
               }}
            />
         </div>
      );
   }
}
