import {
  CHANGE_DATE_BIRTH,
  CHANGE_EMAIL,
  CHANGE_PHONE,
  CHANGE_FIRSTNAME,
  CHANGE_LASTNAME,
  CHANGE_ADDRESS,
  CHANGE_COUNTRY,
  CHANGE_KYC_COUNTRY,
  CHANGE_CITY,
  CHANGE_STATE,
  CHANGE_POSTAL_CODE,
  CHANGE_TAX_ID,
  CHANGE_CUSTOMER,
  CHANGE_PAGE,
  CHANGE_KYC_LEVEL,
} from "./constants";
import structuredClone from "@ungap/structured-clone";
// Instead of using state, I use newState, because instead of the state, I use newState which is a deep clone of the state.
const reducer = (state, action) => {
  const newState = structuredClone(state);
  switch (action.type) {
    case CHANGE_DATE_BIRTH: {
      newState.info.date_of_birth = action.payload;
      break;
    }
    case CHANGE_EMAIL: {
      newState.info.email = action.payload;
      break;
    }
    case CHANGE_PHONE: {
      newState.info.phone_number = action.payload;
      break;
    }
    case CHANGE_FIRSTNAME: {
      newState.info.first_name = action.payload;
      break;
    }
    case CHANGE_LASTNAME: {
      newState.info.last_name = action.payload;
      break;
    }
    case CHANGE_ADDRESS: {
      newState.info.address = action.payload;
      break;
    }
    case CHANGE_COUNTRY: {
      newState.info.country = action.payload;
      break;
    }
    case CHANGE_KYC_COUNTRY: {
      newState.info.kyc_country = action.payload;
      break;
    }
    case CHANGE_CITY: {
      newState.info.city = action.payload;
      break;
    }
    case CHANGE_STATE: {
      newState.info.state = action.payload;
      break;
    }
    case CHANGE_POSTAL_CODE: {
      newState.info.postal_code = action.payload;
      break;
    }
    case CHANGE_TAX_ID: {
      newState.info.tax_id = action.payload;
      newState.customer.tax_id = action.payload;
      break;
    }
    case CHANGE_CUSTOMER: {
      newState.customer = action.payload;
      break;
    }
    case CHANGE_PAGE: {
      newState.page = action.payload;
      break;
    }
    case CHANGE_KYC_LEVEL: {
      newState.kyc_level = action.payload ?? { value: 1 };
      break;
    }

    default:
      return newState;
  }
  return newState;
};

export default reducer;
