import React, { Component } from "react";
import { connect } from "react-redux";

import { NavLink, Route, withRouter } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import "./panel.style.css";
// import MobileLayout from "./MobileLayout";

import Metrics from "../system/tab-system";
import Bets from "../casino/tab-casino";
import Users from "../user/tab-user-root";
import Payments from "../payments/tab-payments";
import Affiliates from "../affiliates/tab-affiliates-root";
import Logout from "./logout";

import MobilePanel from "app/MobilePanel";

import {
  GroupPermissionsFragment,
  permissionGroups,
  PermissionsFragment,
} from "./permissions-fragment";
import StaffManager from "../system/tab-support-manager";
import TabBlazeOriginals from "../originals/tab-blaze-originals";
import { WindowSharp } from "@mui/icons-material";
import Status from "status/tab-status-root";

export const SystemLink = () => (
  <GroupPermissionsFragment group={permissionGroups.SYSTEM}>
    <NavLink to="/system">System</NavLink>
  </GroupPermissionsFragment>
);

export const CasinoLink = () => (
  <GroupPermissionsFragment group={permissionGroups.BETS.GAMES}>
    <NavLink to="/casino">Casino</NavLink>
  </GroupPermissionsFragment>
);

export const PaymentsLink = () => (
  <GroupPermissionsFragment group={permissionGroups.PAYMENTS}>
    <NavLink to="/payments">Payments</NavLink>
  </GroupPermissionsFragment>
);
export const OriginalsLink = () => (
  <PermissionsFragment permission={"BLAZE_ORIGINALS"}>
    <NavLink to="/blaze-originals">Originals</NavLink>
  </PermissionsFragment>
);
export const UsersLink = () => (
  <GroupPermissionsFragment group={permissionGroups.USERS}>
    <NavLink to="/users">Users</NavLink>
  </GroupPermissionsFragment>
);
export const AffiliatesLink = () => (
  <GroupPermissionsFragment group={permissionGroups.AFFILIATES}>
    <NavLink to="/affiliates">Affiliates</NavLink>
  </GroupPermissionsFragment>
);
export const StatusLink = () => (
  <GroupPermissionsFragment group={permissionGroups.MONITORING.GENERAL}>
    <NavLink to="/status">Status</NavLink>
  </GroupPermissionsFragment>
);

class Panel extends Component {
  state = {
    refresh: false,
    toggle: false,
  };

  toggleDrawer = () => {
    let { toggle } = this.state;

    this.setState({ toggle: !toggle });
  };

  render() {
    return (
      <div>
        <MobileView>
          <MobilePanel />
        </MobileView>
        <BrowserView>
          <div className="navigation-show">
            <nav className="main-nav">
              <SystemLink />
              <CasinoLink />
              <PaymentsLink />
              <OriginalsLink />
              <UsersLink />
              <AffiliatesLink />
              <StatusLink />
            </nav>
            <div className="navigation-rightside">
              <div className="user-navigation">{this.props.user} </div>
              <button
                className="button-logout"
                onClick={() => {
                  localStorage.removeItem("access_token");
                  this.props.history.push(`/`);
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </BrowserView>

        <GroupPermissionsFragment group={permissionGroups.SYSTEM}>
          <Route path="/system" component={Metrics} />
        </GroupPermissionsFragment>

        <GroupPermissionsFragment group={permissionGroups.USERS}>
          <Route path="/users" component={Users} />
        </GroupPermissionsFragment>

        <GroupPermissionsFragment group={permissionGroups.BETS}>
          <Route path="/casino" component={Bets} />
        </GroupPermissionsFragment>

        <GroupPermissionsFragment group={permissionGroups.PAYMENTS}>
          <Route path="/payments" component={Payments} />
        </GroupPermissionsFragment>

        <GroupPermissionsFragment group={permissionGroups.STAFF_MANAGER}>
          <Route path="/staff" component={StaffManager} />
        </GroupPermissionsFragment>

        <PermissionsFragment permission={"BLAZE_ORIGINALS"}>
          <Route path="/blaze-originals" component={TabBlazeOriginals} />
        </PermissionsFragment>

        <GroupPermissionsFragment group={permissionGroups.AFFILIATES}>
          <Route path="/affiliates" component={Affiliates} />
        </GroupPermissionsFragment>

        <GroupPermissionsFragment group={permissionGroups.MONITORING}>
          <Route path="/status" component={Status} />
        </GroupPermissionsFragment>

        <Route path="/logout" component={Logout} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.app.user.roles,
  user: state.app.user.email,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Panel));
