import * as types from "./types";
import { toast } from "react-toastify";
import { USER_PAYMENT_ACCOUNTS_UPDATE } from "./types";
import { utc as moment } from "moment";
import { downloadFile } from "../shared/utils/files";
import allPermissions from "../app/permissions.json";

export const selectCurrency = (type) => {
  return {
    type: types.SELECT_CURRENCY,
    payload: type,
  };
};

export const updateUser = (id, email_request_confirmed) => {
  return async (dispatch) => {
    dispatch({
      type: types.USER_UPDATE_LOADING,
      payload: true,
    });

    let qs = "";
    if (email_request_confirmed) {
      qs += "?email_request_confirmed=1";
    }

    const promises = [];

    const getUsersPromise = window.api.get(`/admin/users/` + id + qs);

    promises.push(getUsersPromise);

    if (
      window.permissions.includes("USER_DEPOSITS_VIEW_LIST") ||
      allPermissions.USER_DEPOSITS_VIEW_LIST.find((role) =>
        window.roles.includes(role)
      )
    ) {
      const getDepositsPromise = window.api
        .get(`/admin/users/` + id + "/deposits?page=1")
        .then(({ records }) => records.filter((r) => r.status === "complete"))
        .then((completedDeposits) =>
          completedDeposits.length > 0
            ? completedDeposits[0].created_at
            : moment(0).toISOString()
        );
      promises.push(getDepositsPromise);
    }

    const [user, last_deposit_occurred_at] = await Promise.all(promises);

    const primary = user.wallets.find((w) => w.primary);
    dispatch(selectCurrency(primary.currency_type));

    dispatch({
      type: types.USER_UPDATE,
      payload: {
        ...user,
        currency_types: user.wallets
          .reduce(
            (uniqueCurrencies, wallet) =>
              uniqueCurrencies.findIndex((c) => c === wallet.currency_type) ===
                -1
                ? [...uniqueCurrencies, wallet.currency_type]
                : uniqueCurrencies,
            []
          )
          .sort((a, b) => b.primary - a.primary),
        wallets: user.wallets.map((w) => ({
          ...w,
          deposit_currency_type: w.deposit_currency_type || w.currency_type,
        })),
        last_deposit_occurred_at,
      },
    });
  };
};

export const updateUserBonuses = (id) => {
  return async (dispatch) => {
    const [bonus_inventory = [], consumed_bonuses = []] = await Promise.all([
      window.api.get(`/admin/users/` + id + "/bonus_inventory"),
      window.api.get(`/admin/users/` + id + "/consumed_bonuses"),
    ]);

    dispatch({
      type: types.USER_UPDATE,
      payload: {
        bonus_inventory,
        consumed_bonuses,
      },
    });
  };
};

export const saveUser = (id, data) => {
  return async (dispatch) => {
    const user = await window.api.put(`/admin/users/` + id, data);

    dispatch(updateUser(id));
  };
};

export const updateBalance = (
  user_id,
  id,
  sub_wallet_type,
  amount,
  request
) => {
  return async (dispatch) => {
    try {
      let password = null;
      if (!request) {
        password = window.password_prompt();
        if (password === null) return;
      }

      const note = window.prompt(
        "(For User) Please enter a note for this transaction (ex. crash issue refund)"
      );
      if (note === null) return;

      let staff_note;
      if (request) {
        staff_note = window.prompt(
          "(For Admin) Please enter a reason for this request"
        );
        if (staff_note === null) return;
      }

      await window.api({
        method: "post",
        url: request
          ? "/admin/users/" + user_id + "/request_edit_wallet_balance"
          : "/admin/users/" + user_id + "/edit_wallet_balance",
        data: {
          id: id,
          sub_wallet_type: sub_wallet_type,
          amount: Math.abs(amount),
          debit: amount < 0,
          note: note,
          staff_note: staff_note,
        },
        headers: {
          "x-admin-password": password,
        },
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch(updateUser(user_id));
  };
};

export const updateFingerprints = (user_id, page) => {
  return async (dispatch) => {
    const results = await window.api.get(
      "/admin/users/" + user_id + "/fingerprints",
      {
        params: {
          page,
        },
      }
    );

    dispatch({
      type: types.USER_FINGERPRINTS_UPDATE,
      payload: results,
    });
  };
};

export const updateDeposits = (user_id, page) => {
  return async (dispatch) => {
    const results = await window.api.get(
      "/admin/users/" + user_id + "/deposits",
      {
        params: {
          page,
        },
      }
    );

    dispatch({
      type: types.USER_DEPOSITS_UPDATE,
      payload: results,
    });
  };
};

export const updateWithdrawals = (user_id, page, start, end, exportTo) => {
  if (exportTo) {
    return async (dispatch) => {
      dispatch({ type: types.USER_WITHDRAWALS_DOWNLOADING_REPORT });
      require("axios")({
        url: `/api/admin/users/${user_id}/withdrawals/export/${exportTo}`,
        method: "GET",
        responseType: "blob", // important,
        params: {
          page,
          start,
          end,
          exportTo,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "X-RateLimit-Bypass": "1",
        },
      })
        .then((response) => {
          const extension = exportTo === "xls" ? "xlsx" : exportTo;
          downloadFile(response.data, extension);
        })
        .finally(() =>
          dispatch({ type: types.USER_WITHDRAWALS_DOWNLOADED_REPORT })
        );
    };
  } else {
    return async (dispatch) => {
      const results = await window.api.get(
        `/admin/users/${user_id}/withdrawals`,
        {
          params: {
            page,
            start,
            end,
          },
        }
      );

      dispatch({
        type: types.USER_WITHDRAWALS_UPDATE,
        payload: results,
      });
    };
  }
};

export const updateUserNotes = (user_id, page) => {
  return async (dispatch) => {
    const results = await window.api.get("/admin/users/" + user_id + "/notes", {
      params: {
        page,
      },
    });

    dispatch({
      type: types.USER_NOTES_UPDATE,
      payload: results,
    });
  };
};

export const addNote = (user_id, note) => {
  return async (dispatch) => {
    const results = await window.api.post(
      "/admin/users/" + user_id + "/notes",
      {
        note,
      }
    );

    dispatch(updateUserNotes(user_id, 1));
  };
};

export const editLabel = (user_id, label) => {
  return async (dispatch) => {
    await window.api.put("/admin/users/" + user_id, { label });

    dispatch(updateUser(user_id));
  };
};

export const toggleWhitelist = (user_id) => {
  return async (dispatch) => {
    await window.api.put("/admin/users/" + user_id + "/toggle_whitelist");

    dispatch(updateUser(user_id));
  };
};

export const sendNotification = (user_id, message) => {
  return async (dispatch) => {
    await window.api.post("/admin/users/" + user_id + "/notify", {
      message,
    });

    dispatch({
      type: types.USER_NOTIFICATION_UPDATE,
    });
  };
};

export const sendBroadcast = (message) => {
  return async (dispatch) => {
    await window.api.post("/admin/broadcast", {
      message,
    });

    dispatch({
      type: types.USER_BROADCAST_UPDATE,
    });
  };
};

export const editRoles = (user_id, role, remove = false) => {
  return async (dispatch) => {
    await window.api.put("/admin/users/" + user_id + "/edit_roles/", {
      role,
      remove,
    });

    dispatch(updateUser(user_id));
  };
};

export const addPermission = (userId, permission) => {
  return async (dispatch) => {
    await window.api.put("/admin/users/" + userId + "/permissions/add", {
      permission,
    });

    dispatch(updateUser(userId));
  };
};

export const removePermission = (userId, permission) => {
  return async (dispatch) => {
    await window.api.put("/admin/users/" + userId + "/permissions/remove", {
      permission,
    });

    dispatch(updateUser(userId));
  };
};

export const toggleLock = (user_id) => {
  return async (dispatch) => {
    await window.api.put("/admin/users/" + user_id + "/toggle_lock");

    dispatch(updateUser(user_id));
  };
};

export const getDocuments = (user_id) => {
  return async (dispatch) => {
    let documents = await window.api.get(
      "/admin/users/" + user_id + "/documents"
    );
    dispatch({
      type: types.USER_DOCUMENTS_UPDATE,
      payload: documents,
    });
  };
};

export const getDocument = (user_id, document) => {
  return async (dispatch) => {
    const name = document.original_name;

    let extension = name.slice(name.lastIndexOf(".") + 1).toLowerCase();
    document = await window.api.get(
      "/admin/users/" + user_id + "/documents/" + document.id,
      { responseType: "blob" }
    );

    if (extension === "pdf") {
      let blob = new Blob([document], { type: "application/pdf" });

      let url = window.URL.createObjectURL(blob);
      dispatch({
        type: types.USER_DOCUMENT_UPDATE,
        payload: {
          data: url,
          type: "pdf",
        },
      });
    } else {
      //else image
      let reader = new window.FileReader();
      reader.readAsDataURL(document);
      reader.onload = function () {
        dispatch({
          type: types.USER_DOCUMENT_UPDATE,
          payload: {
            data: reader.result,
            type: "image",
          },
        });
      };
    }
  };
};

export const editDocument = (user_id, document_id, props) => {
  return async (dispatch) => {
    await window.api.put(
      `/admin/users/${user_id}/documents/${document_id}`,
      props
    );
    dispatch(getDocuments(user_id));
  };
};

export const suspend = (user_id, duration, cancel) => {
  return async (dispatch) => {
    if (cancel) {
      await window.api.put("/admin/users/" + user_id + "/suspension/cancel");
    } else {
      let note = window.prompt("Enter reason for suspension");
      await window.api.post("/admin/users/" + user_id + "/suspension", {
        duration,
        note,
      });
    }

    return dispatch(updateUser(user_id));
  };
};

export const updateEmail = (user_id, email, request, staff_note) => {
  return async (dispatch) => {
    await window.api.post(
      request
        ? "/admin/users/" + user_id + "/email_request"
        : "/admin/users/" + user_id + "/email",
      { email, staff_note }
    );
    return dispatch(updateUser(user_id));
  };
};

export const addBonusToInventory = (user_id, bonus_id) => {
  return async (dispatch) => {
    await window.api.post("/admin/users/" + user_id + "/add_bonus", {
      bonus_id,
    });
    return dispatch(updateUserBonuses(user_id));
  };
};

export const forceExpireBonusActivation = (user_id, id) => {
  return async (dispatch) => {
    await window.api.put(
      "/admin/users/" + user_id + "/bonus_activations/" + id,
      {
        status: "expired",
      }
    );
    return dispatch(updateUser(user_id));
  };
};
export const addSpins = (user_id, spins) => {
  return async (dispatch) => {
    await window.api.put("/admin/users/" + user_id + "/add_spins/", {
      spins: spins,
    });
    return dispatch(updateUser(user_id));
  };
};

export const updatePaymentAccounts = (user_id) => {
  return async (dispatch) => {
    let data = await window.api.get(
      "/admin/users/" + user_id + "/payment_accounts"
    );

    return dispatch({
      type: USER_PAYMENT_ACCOUNTS_UPDATE,
      payload: data,
    });
  };
};

export const requestVerification = (user_id, data) => {
  return async (dispatch) => {
    await window.api.post(
      "/admin/users/" + user_id + "/payment_accounts/verify",
      data
    );

    return dispatch(updatePaymentAccounts(user_id));
  };
};

export const approvePaymentVerification = (
  user_id,
  payment_account_verification_id
) => {
  return async (dispatch) => {
    await window.api.post(
      "/admin/users/" + user_id + "/payment_accounts/approve",
      { payment_account_verification_id }
    );

    return dispatch(updatePaymentAccounts(user_id));
  };
};

export const rejectPaymentVerification = (
  user_id,
  payment_account_verification_id,
  note
) => {
  return async (dispatch) => {
    await window.api.post(
      "/admin/users/" + user_id + "/payment_accounts/reject",
      { payment_account_verification_id, note }
    );

    return dispatch(updatePaymentAccounts(user_id));
  };
};

export const updateUserInfo = (user_id, info) => {
  return async (dispatch) => {
    await window.api.post("/admin/users/" + user_id + "/info", { info });

    return dispatch(updateUser(user_id));
  };
};

export const updateCustomerInfo = (user_id, info, request, staff_note) => {
  return async (dispatch) => {
    await window.api.post(
      request
        ? "/admin/users/" + user_id + "/customer/info_request"
        : "/admin/users/" + user_id + "/customer/info",
      { info, staff_note }
    );

    return dispatch(updateUser(user_id));
  };
};

export const createKYCVerification = (
  user_id,
  type,
  masked_account,
  deposit_id
) => {
  return async (dispatch) => {
    await window.api.post(
      "/admin/users/" + user_id + "/kyc_verifications/create",
      { type, masked_account, deposit_id }
    );

    return dispatch(updateUser(user_id));
  };
};

export const updateKYCVerification = (verification_id, status, user_id) => {
  return async (dispatch) => {
    await window.api.post("/admin/kyc_verifications/update", {
      verification_id,
      status,
    });

    if (user_id) {
      return dispatch(updateUser(user_id));
    }
  };
};


export const updateBonusRounds = (user_id) => {
  return async (dispatch) => {
    let rounds = await window.api.get(
      "/admin/users/" + user_id + "/bonus_rounds"
    );

    dispatch({
      type: types.USER_BONUS_ROUNDS_UPDATE,
      payload: rounds,
    });
  };
};

export const notifyKYCVerification = (user_id, props) => {
  return async (dispatch) => {
    await window.api.post(
      `/admin/users/${user_id}/kyc_verifications/notify`,
      props
    );
    if (user_id) {
      return dispatch(updateUser(user_id));
    }
  };
};

export const getReferralData = (user_id) => {
  return async (dispatch) => {
    const data = await window.api.get(`/admin/users/${user_id}/referral_code`);
    dispatch({
      type: types.USER_REFERRALS_UPDATE,
      payload: data,
    });
  };
};

export const closeAccount = (user_id, remove, reason) => {
  return async (dispatch) => {
    const data = await window.api.put(`/admin/users/${user_id}/close_account`, {
      remove, reason
    });
    dispatch(updateUser(user_id));
  };
};
export function uploadVerificationFile(filelist, id, sub_type, user_id, back) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("file", filelist[0]);

    try {
      if (back) {
        await window.api.put(
          `/admin/users/${user_id}/kyc_verifications/upload?verification_id=${id}&sub_type=${sub_type}&back=true`,
          data
        );
      } else {
        await window.api.put(
          `/admin/users/${user_id}/kyc_verifications/upload?verification_id=${id}&sub_type=${sub_type}`,
          data
        );
      }

      dispatch(updateUser(user_id));
      dispatch(getDocuments(user_id));

      toast.info("uploaded");
    } catch (err) {
      toast.error(err.message);
    }
  };
}

export const requestRefund = (user_id, wallet_id, amount, note, request) => {
  return async (dispatch) => {
    try {
      let password = null;
      if (!request) {
        password = window.password_prompt();
        if (password === null) return;
      }

      if (amount !== Math.abs(amount).toFixed(2))
        return window.alert("Enter amount in valid format, like 3.34");

      await window.api({
        method: "post",
        url: "/admin/users/" + user_id + "/request_refund",
        data: {
          wallet_id: wallet_id,
          amount: Math.abs(amount).toFixed(2),
          note,
        },
        headers: {
          "x-admin-password": password,
        },
      });
      toast.success("Refund created. Waiting for approval");
    } catch (err) {
      toast.error(err.message);
    }

    dispatch(updateUser(user_id));
  };
};

export const updateOTPFingerprints = (user_id) => {
  return async (dispatch) => {
    const results = await window.api.get(
      "/admin/users/" + user_id + "/otp_fingerprints"
    );

    dispatch({
      type: types.USER_OTP_FINGERPRINTS_UPDATE,
      payload: results,
    });
  };
};

export const deletePhoneNumber = async (userId, phoneNumber) => {
  await window.api.delete(
    "/admin/users/" + userId + "/otp_fingerprints",
    { data: { phone_number: phoneNumber, user_id: userId } }
  );
};

export const resendEmailConfirmation = async (user_id) => {
    await window.api.post("/admin/users/" + user_id + "/resend_email");
};